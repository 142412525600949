<div class="flex flex-nowrap gap-2 items-center mb-4" [formGroup]="parent">
  <p-checkbox [formControlName]="control" [value]="true" [binary]="true"></p-checkbox>
  <span class="text-xs terms">
    I agree to Rally <a href="/policies#terms-of-use" target="_blank">Terms of Use</a> and
    <a href="/policies#privacy-policy" target="_blank">Privacy Policy</a>.</span
  >
</div>
<div class="-mt-[10px]" *ngIf="hasError">
  <div class="text-sm text-red-400">
    <span>Please review and check the consent checkbox</span>
  </div>
</div>
