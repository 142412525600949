<ng-container *ngIf="algoliaKey$ | async; else loading">
  <div class="autocomplete-container" [formGroup]="form">
    <label class="block text-gray-700 text-sm font-bold mb-2 w-full" *ngIf="label">{{ label }}</label>
    <p-autoComplete
      formControlName="search"
      [suggestions]="suggestions"
      [placeholder]="placeholder"
      [completeOnFocus]="completeOnFocus"
      (completeMethod)="onSearch($event)"
      (onSelect)="onSelect($event)"
      [appendTo]="appendTo"
      [optionLabel]="valueLabel"
      data-hj-allow>
      <ng-template let-result pTemplate="item">
        <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: result }"></ng-template>
      </ng-template>
      <ng-container *ngIf="otherValue">
        <ng-template pTemplate="empty">
          <div class="flex gap-2 items-center">
            <span (click)="onOtherValueClicked()">{{ otherValueLabel }}</span>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="selectOtherValue">
        <ng-template pTemplate="empty">
          <div class="flex gap-2 items-center">
            <span (click)="onCurrentValueClicked(form.value.search)">{{ form.value.search }}</span>
          </div>
        </ng-template>
      </ng-container>
    </p-autoComplete>
  </div>
</ng-container>

<ng-template #loading>
  <p-skeleton width="100%" height="54px"></p-skeleton>
</ng-template>
