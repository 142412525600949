import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { SelectOption } from '@shared/interface/common';
import { Address } from '@shared/interface/user';
import { AuthService } from '@shared/services/auth.service';
import { UserService } from '@shared/services/user.service';
import { US_STATES } from '@shared/utils/constants';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs';
import { NgIf } from '@angular/common';
import { ButtonDirective } from 'primeng/button';
import { GoogleAddressComponent } from '../google-address/google-address.component';

@Component({
  selector: 'app-find-representatives',
  templateUrl: './find-representatives.component.html',
  styleUrls: ['./find-representatives.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, GoogleAddressComponent, ButtonDirective, NgIf]
})
export class FindRepresentativesComponent implements OnInit {
  public form: UntypedFormGroup;
  public formLoading: boolean = false;
  public statesOptions: SelectOption[] = Object.keys(US_STATES).map(
    (k: string) => ({ value: k, label: US_STATES[k as keyof typeof US_STATES] }) as SelectOption
  );

  private dialogRef: DynamicDialogRef = inject(DynamicDialogRef);
  private fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  private authService: AuthService = inject(AuthService);
  private userService: UserService = inject(UserService);
  private toasterService: ToastrService = inject(ToastrService);

  public constructor() {
    this.form = this.fb.group({
      address: ['', Validators.required],
      streetName: [''],
      cityName: [''],
      stateName: [''],
      zipcode: ['']
    });
  }

  public ngOnInit(): void {}

  public get submitDisabled(): boolean {
    return this.form.invalid || this.formLoading;
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.formLoading = true;
      const address: string = this.form.value.address;
      const userAddress: Address = {
        address: this.form.value.address,
        cityName: this.form.value.cityName,
        stateName: this.form.value.stateName,
        streetName: this.form.value.streetName,
        zipcode: this.form.value.zipcode
      };

      if (!this.form.value.zipcode || this.form.value.zipcode === '') {
        this.formLoading = false;
        this.toasterService.error('Please enter a valid street address with a zipcode');
        return;
      }

      if (this.authService.currentUserUID) {
        this.userService
          .updateUserRepresentatives(address)
          .pipe(take(1))
          .subscribe(
            (res) => {
              if (res.status === 200) {
                // TODO: fix the permission error here
                this.authService.currentUser.address = userAddress;
                this.formLoading = false;
                this.toasterService.success('Representatives updated successfully');
                this.dialogRef.close(userAddress);
              } else {
                this.formLoading = false;
                this.toasterService.error(res.message);
              }
            },
            (error) => {
              console.error(error);
              this.formLoading = false;
              this.toasterService.error('Failed to update representatives');
            }
          );
      } else {
        this.userService
          .getUserRepresentatives(address)
          .pipe(take(1))
          .subscribe((res: any) => {
            this.formLoading = false;
            if (res.status === 200) {
              this.authService.unauthenticatedUserCache.address = userAddress;
              this.authService.unauthenticatedUserCache.representatives = res.data;
              this.userService.updateSessionUserData(this.authService.unauthenticatedUserCache);
              this.dialogRef.close(userAddress);
            } else {
              this.toasterService.error(res.message);
            }
          });
      }
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
