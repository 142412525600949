import { Component, inject, OnInit, TemplateRef } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet]
})
export class GenericModalComponent implements OnInit {
  public templateRef: TemplateRef<any>;

  private dialogConfig: DynamicDialogConfig<{ template: TemplateRef<any> }> = inject(DynamicDialogConfig);

  public constructor() {
    this.templateRef = this.dialogConfig.data?.template as TemplateRef<any>;
  }

  public ngOnInit(): void {}

  public get bodyTemplate(): TemplateRef<any> {
    return this.templateRef;
  }
}
