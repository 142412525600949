import { Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [ProgressBarModule]
})
export class ProgressBarComponent implements OnInit, OnChanges {
  @Input() public id: string = '';
  @Input() public color: string = '';
  @Input() public value: number = 0;

  public styleEl: HTMLStyleElement = document.createElement('style');

  //generate unique attribule which we will use to minimise the scope of our dynamic style
  public uniqueAttr = `app-progress-bar-color-${this.id}`;

  public constructor(private el: ElementRef) {
    const nativeEl: HTMLElement = this.el.nativeElement;
    nativeEl.setAttribute(this.uniqueAttr, '');
    nativeEl.appendChild(this.styleEl);
  }

  public ngOnInit(): void {}

  public ngOnChanges(): void {
    this.updateColor();
  }

  private updateColor(): void {
    if (this.color !== '') {
      // update dynamic style with the uniqueAttr
      this.styleEl.innerText = `
      [${this.uniqueAttr}] .mat-progress-bar-fill::after {
        background-color: ${this.color};
      }

      .mat-progress-bar-element.mat-progress-bar-buffer {
        border-color: ${this.color} !important;
      }
    `;
    }
  }
}
