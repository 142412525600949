import { Component, inject, OnInit } from '@angular/core';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  standalone: true,
  imports: [NgIf, ImageCropperModule]
})
export class ImageCropperComponent implements OnInit {
  public croppedImage: string;
  public cropperLoading: boolean = true;
  public event: Event;
  public acceptedFileTypes: string[];
  public aspectRatio: number;

  private dialogRef: DynamicDialogRef = inject(DynamicDialogRef);
  private dialogConfig: DynamicDialogConfig<{ event: Event; acceptedFileTypes: string[]; aspectRatio: number }> = inject(DynamicDialogConfig);
  private toasterService: ToastrService = inject(ToastrService);

  public constructor() {
    this.event = this.dialogConfig.data?.event as Event;
    this.acceptedFileTypes = this.dialogConfig.data?.acceptedFileTypes as string[];
    this.aspectRatio = this.dialogConfig.data?.aspectRatio as number;
  }

  public ngOnInit(): void {}

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64 as string;
  }

  public cropperReady() {
    this.cropperLoading = false;
  }

  public loadImageFailed() {
    this.toasterService.error('Failed to load image. Please try again or use a different image.');
    this.dialogRef.close(false);
  }

  public onCrop(): void {
    this.dialogRef.close(this.croppedImage);
  }
}
