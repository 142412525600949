import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import videojs, { VideoJsPlayerOptions } from 'video.js';

@Component({
  selector: 'app-vjs-player',
  templateUrl: './vjs-player.component.html',
  styleUrls: ['./vjs-player.component.scss'],
  standalone: true
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) public target: ElementRef;

  @Input() public options: VideoJsPlayerOptions;

  private player: videojs.Player;

  public constructor() {}

  public ngOnInit(): void {
    this.player = videojs(this.target.nativeElement, this.options, () => {
      // force the parent element to take the container's full width
      this.setPlayerSize();
    });

    const resizeObserver = new ResizeObserver(() => {
      this.setPlayerSize();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }

  public ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

  private setPlayerSize(): void {
    const playerParent = (this.target.nativeElement as HTMLElement).parentElement;
    if (playerParent && playerParent.parentElement && playerParent.parentElement.parentElement) {
      playerParent.style.width = playerParent.parentElement.parentElement.offsetWidth + 'px';
      playerParent.style.height = 'auto';
    }
  }
}
