<div class="login-dialog">
  <div class="login-dialog-content">
    <div class="login-dialog-body">
      <div class="border border-solid border-rs-mid-teal px-3 py-1 p-0 bg-rs-light-teal mb-4" *ngIf="showMigrationAlert">
        <p class="m-0 text-xs text-rs-mid-teal">
          In order to provide a great experience, we have recently rebuilt Rally Starter from the ground up. During the transition, we migrated our database
          which may require you to reset your passwords. We apologize for the inconvenience.
        </p>
      </div>
      <div class="flex flex-col lg:flex-row gap-4" [ngClass]="isRegister ? 'items-center lg:items-start' : 'items-center'">
        <div class="flex flex-col lg:w-2/5 lg:max-w-lg" [ngClass]="isRegister ? 'items-center' : ''">
          <p *ngIf="isRegister" class="text-center details">
            To protect our platform from spam and bots, we allow only one action per day for all non-confirmed users. Please register to take unlimited actions
            for change on Rally Starter.
          </p>
          <button class="outline-none mb-4" (click)="onLoginWithGoogle()">{{ getActionLabel }} with Google</button>
          <button class="outline-none mb-4" (click)="onLoginWithTwitter()">{{ getActionLabel }} with Twitter</button>
          <button class="outline-none mb-4" (click)="onLoginWithFacebook()">{{ getActionLabel }} with Facebook</button>
        </div>
        <div class="flex flex-col lg:w-1/5 lg:max-w-[120px] items-center justify-center">
          <h5 class="text-center font-semibold text-rs-darkest-grey">OR</h5>
        </div>
        <div [formGroup]="form" class="flex flex-col gap-2 items-center lg:items-end lg:w-2/5">
          <app-input [parent]="form" control="email" placeholder="user@example.com" label="Email" class="w-full"></app-input>
          <app-input [parent]="form" control="password" placeholder="Enter Password" label="Password" type="password" class="w-full"></app-input>
          <app-input [parent]="form" control="confirmPassword" label="Confirm Password" type="password" class="w-full" *ngIf="isRegister"></app-input>
          <div class="flex flex-nowrap justify-end items-end">
            <div class="flex flex-col items-center justify-center lg:justify-end lg:items-end">
              <div class="flex flex-nowrap gap-2 justify-end items-center mb-3" *ngIf="isRegister">
                <p-checkbox
                  formControlName="terms"
                  [value]="true"
                  [binary]="true"
                  [ngClass]="{ required: isRegister && form.controls.terms.invalid && form.controls.terms.touched }"></p-checkbox>
                <span class="text-xs terms text-rs-darker-grey">
                  I agree to Rally <a href="/policies#terms-of-use" target="_blank">Terms of Use</a>,
                  <a href="/policies#privacy-policy" target="_blank">Privacy Policy</a>, and
                  <a href="/policies#copyright-policy" target="_blank">Copyright Policy</a>.</span
                >
              </div>
              <a *ngIf="!isRegister" (click)="onForgotPassword()" class="mb-4">Forgot password?</a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center lg:justify-end">
        <div class="flex items-center lg:items-end flex-col">
          <button class="login-btn" (click)="onSubmit()">
            <span>{{ getActionLabel }}</span>
            <i class="fas fa-circle-notch fa-spin ml-2" *ngIf="formLoading"></i>
          </button>
          <div class="flex flex-nowrap justify-center items-center" class="mt-4">
            <a (click)="onToggleView()" class="tetext-rs-darkest-grey">
              {{ isRegister ? 'Already have an account? Log in.' : "Don't have an account? Join." }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
