import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { RadioOption } from '@shared/interface/common';
import { RadioButtonClickEvent, RadioButtonModule } from 'primeng/radiobutton';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgFor, RadioButtonModule]
})
export class InputRadioComponent implements OnInit {
  @Input() public parent!: UntypedFormGroup;
  @Input() public control!: string;
  @Input() public label: string = '';
  @Input() public readonly: boolean = false;
  @Input() public alignment: 'horizontal' | 'vertical' = 'horizontal';
  @Input() public options: RadioOption[] = [];
  @Input() public class: string = '';

  @Output() public readonly changed: EventEmitter<any> = new EventEmitter();

  public inputControl: AbstractControl;

  public constructor() {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }

    this.inputControl = this.parent.get(this.control) as AbstractControl;
  }

  public get hasError(): boolean {
    return this.inputControl.invalid && this.inputControl.dirty;
  }

  public get requiredField(): boolean {
    return this.inputControl.hasValidator(Validators.required);
  }

  public get inputAlignment(): string {
    return `${this.alignment === 'horizontal' ? 'flex-row wrap  gap-4' : 'flex-column gap-1'} ${this.class}`;
  }

  public onChange(event: RadioButtonClickEvent) {
    this.inputControl.setValue(event.value);
    this.changed.emit(event.value);
  }
}
