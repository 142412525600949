import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CheckboxOption } from '@shared/interface/common';
import { CheckboxModule } from 'primeng/checkbox';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgFor, CheckboxModule, ReactiveFormsModule, FormsModule]
})
export class InputCheckboxComponent implements OnInit {
  @Input() public parent!: UntypedFormGroup;
  @Input() public control!: string;
  @Input() public label: string = '';
  @Input() public readonly: boolean = false;
  @Input() public alignment: 'horizontal' | 'vertical' = 'horizontal';
  @Input() public options: CheckboxOption[] = [];

  public inputControl: AbstractControl;
  public selectedOptions: any[] = [];

  public constructor() {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }

    this.inputControl = this.parent.get(this.control) as AbstractControl;
    if (this.inputControl.value) {
      this.selectedOptions = this.inputControl.value;
    }
  }

  public get requiredField(): boolean {
    return this.inputControl.hasValidator(Validators.required) || this.inputControl.hasValidator(Validators.requiredTrue);
  }

  public get inputAlignment(): string {
    return this.alignment === 'horizontal' ? 'row wrap' : 'column';
  }

  public onChange(): void {
    this.inputControl.setValue(this.selectedOptions);
  }
}
