<div class="forgot-password-dialog">
  <div class="forgot-password-dialog-content">
    <div class="forgot-password-dialog-body" [formGroup]="form">
      <p>Enter your email address to receive password reset instructions.</p>
      <app-input [parent]="form" control="email" label="Email" placeholder="user@example.com"></app-input>
    </div>
    <div class="forgot-password-dialog-footer my-8 flex flex-row flex-nowrap justify-start items-center">
      <button
        pButton
        class="p-button-outlined flex flex-row flex-nowrap justify-center items-center gap-[10px]"
        (click)="onSubmit()"
        [disabled]="form.invalid || formLoading">
        <span>Send Instructions</span>
        <i class="fas fa-circle-notch fa-spin" *ngIf="formLoading"></i>
      </button>
    </div>
  </div>
</div>
