<div class="embed-rally-modal">
  <div class="embed-rally-modal-content">
    <div class="embed-rally-modal-body">
      <div class="flex flex-col sm:flex-row gap-4">
        <div class="flex flex-col gap-y-3 max-w-sm" [formGroup]="form">
          <h5 class="m-0 text-base">Options</h5>
          <app-select [parent]="form" control="font" [optionGroups]="customFontOptions" label="Custom font (optional)"></app-select>
          <app-input [parent]="form" control="color" label="Custom color (optional)"></app-input>
          <app-input-checkbox [parent]="form" control="hideOptions" label="" [options]="hideOptions" alignment="vertical"></app-input-checkbox>
          <h5 class="m-0 text-base">Show More Actions</h5>
          <app-input-checkbox [parent]="form" control="includeOptions" label="" [options]="includeOptions" alignment="vertical"></app-input-checkbox>
          <h5 class="m-0 text-base">Widget Size</h5>
          <app-input-checkbox [parent]="form" control="sizingOptions" label="" [options]="sizingOptions" alignment="vertical"></app-input-checkbox>
          <app-input [parent]="form" control="width" label="Width (pixels)" *ngIf="sizingOptions[1].checked"></app-input>
          <app-input [parent]="form" control="height" label="Height (pixels)" *ngIf="sizingOptions[1].checked"></app-input>
          <span class="text-xs">Embed this rally on any website by copying and pasting the code below.</span>
          <textarea [value]="embedCode" class="p-2 font-poppins" rows="4"></textarea>
          <button class="rs-button clear-black" (click)="onCopy()">Copy to Clipboard</button>
        </div>
        <div class="flex flex-col">
          <h5 class="m-0 text-base mb-2">Preview</h5>
          <span class="text-xs"
            >Rally Starter Action Widgets are fully responsive elements that automatically fill the width of the container they are added to, from sidebars to
            full pages, on computer or mobile.
          </span>
          <div class="w-full mt-4 border border-solid border-gray-300">
            <iframe [src]="iframeSrc | async" class="w-full h-[500px] mt-4" [frameBorder]="0"></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="embed-rally-modal-footer my-8 flex flex-row flex-nowrap justify-start items-center"></div>
  </div>
</div>
