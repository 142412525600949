import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '@shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgIf } from '@angular/common';
import { ButtonDirective } from 'primeng/button';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, InputComponent, ButtonDirective, NgIf]
})
export class ForgotPasswordDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public formLoading: boolean = false;

  private fb: FormBuilder = inject(FormBuilder);
  private authService: AuthService = inject(AuthService);
  private toasterService: ToastrService = inject(ToastrService);
  private dialogRef: DynamicDialogRef = inject(DynamicDialogRef);

  public constructor() {
    this.form = this.fb.group({
      email: ['', Validators.email]
    });
  }

  public ngOnInit(): void {}

  public onSubmit(): void {
    if (this.form.valid) {
      this.formLoading = true;
      this.authService.resetPassword(this.form.value.email.trim()).then(() => {
        this.formLoading = false;
        this.toasterService.success('Please check your email for instructions on how to reset your password.');
        this.dialogRef.close('close');
      });
    }
  }
}
