import { Component, OnInit } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-component-loading',
  templateUrl: './component-loading.component.html',
  styleUrls: ['./component-loading.component.scss'],
  standalone: true,
  imports: [FaIconComponent]
})
export class ComponentLoadingComponent implements OnInit {
  public constructor() {}

  public ngOnInit(): void {}
}
