import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-scroll-indicator',
  templateUrl: './scroll-indicator.component.html',
  styleUrls: ['./scroll-indicator.component.scss'],
  standalone: true,
  imports: [FaIconComponent]
})
export class ScrollIndicatorComponent implements OnInit, AfterViewInit {
  @Input() public height: number = 40;
  @Input() public targetContainer: HTMLDivElement;

  private atBottom: boolean = false;

  public constructor() {}

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {
    this.targetContainer.onscroll = this.targetScrolled.bind(this);
  }

  public get show(): boolean {
    return this.targetContainer.scrollHeight > this.targetContainer.clientHeight && !this.atBottom;
  }

  private targetScrolled(): void {
    this.atBottom = this.targetContainer.scrollHeight - this.targetContainer.scrollTop === this.targetContainer.clientHeight;
  }
}
