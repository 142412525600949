<a class="no-underline" [routerLink]="['/rally', data.id, data.slug]">
  <div class="rally-card">
    <div class="rally-card-header">
      <div class="category">
        <div class="category-border" [style]="'background-color:' + categoryColor"></div>
        <a class="category-link" href="">{{ category }}</a>
      </div>
      <span class="rally-actions">{{ data.totalActions || 0 }} Actions</span>
    </div>
    <div class="rally-card-image">
      <div class="bg-cover bg-center bg-no-repeat bg-grey-light" [ngStyle]="{ 'background-image': 'url(' + getImage(data) + ')' }"></div>
    </div>
    <h3 class="h5 text-left overflow-hidden text-ellipsis font-semibold min-h-[77px] no-underline">{{ data.name }}</h3>
  </div>
</a>
