import { Component, Input, OnInit, inject } from '@angular/core';
import { Rally } from '@interface/rally';
import { FileService } from '@shared/services/file.service';
import { CATEGORIES } from '@shared/utils/constants';
import { sample } from 'lodash';
import { NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-rally-card',
  templateUrl: './rally-card.component.html',
  styleUrls: ['./rally-card.component.scss'],
  standalone: true,
  imports: [RouterLink, NgStyle]
})
export class RallyCardComponent implements OnInit {
  @Input() public data: Partial<Rally>;

  public category: string = '';

  private fileService: FileService = inject(FileService);

  public constructor() {}

  public ngOnInit(): void {
    this.category = sample(this.data.categories) as string;
  }

  public get getCategory(): string {
    if (this.data.categories && this.data.categories.length > 0) {
      return this.data.categories[0];
    }

    return '';
  }

  public get categoryColor(): string {
    const cardCat = this.data.categories && this.data.categories.length > 0 ? this.data.categories[0] : '';
    const cat = CATEGORIES.find((category) => category.value === cardCat);

    return cat ? cat.color : '';
  }

  public getImage(rally: Partial<Rally>): string {
    return this.fileService.getImageUrl(rally);
  }
}
