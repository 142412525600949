import { NgFor, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, ContentChildren, ElementRef, HostListener, inject, Input, QueryList, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ScrollSectionDirective } from '@shared/directives/scroll-section.directive';
import { HyphenPipe } from '@shared/pipes/hyphen.pipe';

@Component({
  selector: 'app-section-scroller',
  templateUrl: './section-scroller.component.html',
  styleUrls: ['./section-scroller.component.scss'],
  standalone: true,
  imports: [NgFor, RouterModule, HyphenPipe],
  providers: [HyphenPipe]
})
export class SectionScrollerComponent implements AfterViewInit {
  @ContentChildren(ScrollSectionDirective) public sections!: QueryList<ScrollSectionDirective>;
  @ViewChild('sideNav', { static: false }) public sideNav: ElementRef<HTMLElement>;
  @ViewChild('contentWrap', { static: false }) public contentWrap: ElementRef<HTMLElement>;

  @Input() public stickyOnEnd: boolean = false;
  @Input() public topOffset: number = 200;
  @Input() public sectionPadding: number = 40;

  public activeIdx: number = 0;

  private viewportScroller: ViewportScroller = inject(ViewportScroller);
  private router: Router = inject(Router);
  private hyphenPipe: HyphenPipe = inject(HyphenPipe);

  public constructor() {}

  public ngAfterViewInit(): void {
    this.updateUrlWithFirstFragment();
  }

  @HostListener('window:scroll', ['$event'])
  public onScroll() {
    // Sticky sidebar nav
    if (window.scrollY > this.topOffset) {
      this.sideNav.nativeElement.classList.add('md:fixed');
      this.sideNav.nativeElement.classList.remove('md:absolute');
    } else {
      this.sideNav.nativeElement.classList.add('md:absolute');
      this.sideNav.nativeElement.classList.remove('md:fixed');
    }

    if (window.scrollY > this.contentWrap.nativeElement.clientHeight - this.topOffset * 2) {
      if (!this.stickyOnEnd) {
        this.sideNav.nativeElement.classList.add('md:absolute');
        this.sideNav.nativeElement.classList.remove('md:fixed');
        this.sideNav.nativeElement.classList.add('bottom-0');
        this.sideNav.nativeElement.classList.remove('top-0');
      }
    } else {
      this.sideNav.nativeElement.classList.add('top-0');
      this.sideNav.nativeElement.classList.remove('bottom-0');
    }

    this.sections.forEach((s: ScrollSectionDirective, idx: number) => {
      const nextSection = this.sections.get(idx + 1);
      const currentSectionTop = s.el.nativeElement.offsetTop;

      if (nextSection) {
        const nextSectionTop = nextSection.el.nativeElement.offsetTop;
        if (window.scrollY >= currentSectionTop + this.sectionPadding && window.scrollY < nextSectionTop + this.sectionPadding) {
          if (this.activeIdx !== idx) {
            this.activeIdx = idx;
            const fragment = this.hyphenPipe.transform(s.sectionLabel);
            this.updateUrlWithFragment(fragment);
          }
        }
      } else {
        if (window.scrollY + 10 >= currentSectionTop) {
          if (this.activeIdx !== idx) {
            this.activeIdx = idx;
            const fragment = this.hyphenPipe.transform(s.sectionLabel);
            this.updateUrlWithFragment(fragment);
          }
        }
      }
    });
  }

  public onClick(idx: number): void {
    this.viewportScroller.scrollToAnchor(`section_scroll_${idx}`);
    setTimeout(() => {
      this.activeIdx = idx;
    }, 100);
  }

  private updateUrlWithFragment(fragment: string): void {
    const currentUrl = this.router.url.split('#')[0];
    history.replaceState(null, '', `${currentUrl}#${fragment}`);
  }

  private updateUrlWithFirstFragment() {
    if (!this.router.url.includes('#')) {
      const firstFragment = this.hyphenPipe.transform(this.sections.first.sectionLabel);
      this.updateUrlWithFragment(firstFragment);
    }
  }
}
