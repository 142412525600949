import { Injectable } from '@angular/core';
import {
  getDownloadURL,
  percentage,
  ref,
  Storage,
  uploadBytesResumable,
  UploadResult,
  uploadString,
  UploadTaskSnapshot,
  listAll,
  deleteObject
} from '@angular/fire/storage';
import { Rally } from '@shared/interface/rally';
import { finalize, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private basePath = '/uploads';

  public constructor(private storage: Storage) {}

  public pushFileToStorage(fileUpload: File, folderPath: string, cb: (url: string) => void): Observable<{ progress: number; snapshot: UploadTaskSnapshot }> {
    const filePath = `${this.basePath}${folderPath}/${fileUpload.name}`;
    const storageRef = ref(this.storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, fileUpload);

    uploadTask.on('state_changed', async (snapshot) => {
      if (snapshot.state === 'success') {
        const downloadURL = await getDownloadURL(storageRef);
        cb(downloadURL);
      }
    });

    return percentage(uploadTask).pipe(
      finalize(async () => {
        cb(await getDownloadURL(storageRef));
      })
    );
  }

  public pushBase64ToStorage(fileName: string, base64: string, path: string = this.basePath, cb: (url: string) => void): Promise<UploadResult> {
    const fixPath = fileName.replace(`'`, '');
    const filePath = `${path}/${fixPath}`;
    const storageRef = ref(this.storage, filePath);
    const uploadTask = uploadString(storageRef, base64, 'data_url');

    return uploadTask.finally(async () => {
      cb(await getDownloadURL(storageRef));
    });
  }

  public getOptimizedFileURL(originalFileURL: string, fileName: string, width: 1200 | 700 | 300 | 200): string {
    const height = Math.round((width / 16) * 9);
    const img = originalFileURL.replace('?alt=media', `_${width}x${height}?alt=media`);
    return img;
  }

  public getBase64 = (file: File): Promise<string> =>
    new Promise((resolvePromise) => {
      const reader = new FileReader();
      reader.onload = () => resolvePromise(reader.result as string);
      reader.readAsDataURL(file);
    });

  public async getFolderContents(path: string): Promise<{ dlURL: string; path: string }[]> {
    const storageRef = ref(this.storage, path);
    const uploadedFiles = await listAll(storageRef);

    return await Promise.all(uploadedFiles.items.map(async (sf) => ({ dlURL: await getDownloadURL(sf), path: sf.fullPath })));
  }

  public async deleteFile(path: string): Promise<void> {
    const storageRef = ref(this.storage, path);

    return deleteObject(storageRef);
  }

  public getImageUrl(rally: Partial<Rally>): string {
    if (rally.video) {
      const params = new URL(rally.video);
      const youtubeId = params.searchParams.has('v') ? params.searchParams.get('v') || '' : '';

      return `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`;
    }

    if (rally.image) {
      const img = this.getOptimizedFileURL(rally.image, rally.name || '', 300);

      return img;
    }

    return '';
  }
}
