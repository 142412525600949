import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { take } from 'rxjs';

import { NgIf } from '@angular/common';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf]
})
export class ImageUploadComponent implements OnInit {
  @ViewChild('file') public file: ElementRef<HTMLInputElement>;

  @Input() public parent!: UntypedFormGroup;
  @Input() public control!: string;
  @Input() public placeholder: string = '';
  @Input() public label: string = 'Upload Image';
  @Input() public uploadLinkLabel: string = 'Upload different image';
  @Input() public readonly: boolean = false;
  @Input() public acceptedFileTypes: string[] = ['image/jpeg', 'image/png', 'image/webp', 'image/jpg'];
  @Input() public aspectRatio: number = 16 / 9;
  @Input() public maxSizeBytes: number = 10485760; // 10MB

  public inputControl: AbstractControl;
  public previewURL: string = '';
  public imageChangedEvent: any = '';
  public croppedImage: any = '';

  private dialog: DialogService = inject(DialogService);

  public constructor() {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }

    this.inputControl = this.parent.get(this.control) as AbstractControl;

    if (this.inputControl.value) {
      this.previewURL = this.inputControl.value;
    }
  }

  public get hasError(): boolean {
    return this.inputControl.invalid && this.inputControl.dirty;
  }

  public get requiredField(): boolean {
    return this.inputControl.hasValidator(Validators.required);
  }

  public get getErrorMessage(): string {
    if (this.hasError && this.inputControl.hasError('required')) {
      return 'This field is required.';
    }

    if (this.inputControl.hasError(this.control)) {
      return this.inputControl.getError(this.control);
    }

    if (this.inputControl.hasError('maxlength')) {
      return 'Max character length exceeded';
    }

    return 'Please enter a valid input';
  }

  public onFileChange(event: Event): void {
    this.inputControl.markAsDirty();
    const target = event.target as HTMLInputElement;
    const file = target.files?.item(0);

    // Validate file type
    if (!file || !this.acceptedFileTypes.includes(file.type)) {
      this.inputControl.setErrors({ [this.control]: 'Invalid image type. Acceptable formats are JPEG, JPG, WEBP, and PNG.' });
      return;
    }

    // Validate file size
    if (file.size > this.maxSizeBytes) {
      this.inputControl.setErrors({ [this.control]: 'File size is too large. Max file size is 10MB.' });
      return;
    }

    this.dialog
      .open(ImageCropperComponent, {
        showHeader: true,
        data: {
          event,
          acceptedFileTypes: this.acceptedFileTypes,
          aspectRatio: this.aspectRatio
        },
        styleClass: 'image-cropper-dialog'
      })
      .onClose.pipe(take(1))
      .subscribe((result: string) => {
        if (result) {
          this.previewURL = result;
          this.inputControl.setValue(result);
        }

        this.file.nativeElement.value = '';
      });
  }
}
