<div class="input-container select-form-field" [formGroup]="parent">
  <label *ngIf="label">{{ label }} <span *ngIf="requiredField" class="required">*</span></label>

  <select [formControlName]="control" #select (change)="selected.emit($event)">
    <option *ngIf="placeholder !== ''" value="" [disabled]="true">{{ placeholder }}</option>
    <ng-container *ngIf="options && options.length > 0">
      <option *ngIf="!options || options.length === 0" [disabled]="true">No options available</option>
      <option *ngFor="let option of options" [value]="option.value" [innerHTML]="option.label"></option>
    </ng-container>
    <ng-container *ngIf="optionGroups && optionGroups.length > 0">
      <optgroup *ngFor="let group of optionGroups" [label]="group.label">
        <option *ngFor="let option of group.options" [value]="option.value" [innerHTML]="option.label"></option>
      </optgroup>
    </ng-container>
  </select>

  <i *ngIf="loading" matSuffix class="fas fa-circle-notch fa-spin"></i>

  <div class="error-message" *ngIf="hasError">
    {{ getErrorMessage }}
  </div>
</div>
