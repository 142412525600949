import { Component, Input, OnInit } from '@angular/core';
import { BlogCardData } from '@shared/interface/common';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class BlogCardComponent implements OnInit {
  @Input() public data: BlogCardData;
  @Input() public backgroundSize: string = 'cover';

  public constructor() {}

  public ngOnInit(): void {}
}
