import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BalloonContainerDirective } from '@shared/directives/balloon-container.directive';
import { random } from 'lodash';

import { BalloonComponent } from '../balloon/balloon.component';
import { BalloonContainerDirective as BalloonContainerDirective_1 } from '../../directives/balloon-container.directive';

@Component({
  selector: 'app-balloon-animation',
  templateUrl: './balloon-animation.component.html',
  styleUrls: ['./balloon-animation.component.scss'],
  standalone: true,
  imports: [BalloonContainerDirective_1]
})
export class BalloonAnimationComponent implements OnInit {
  @ViewChild(BalloonContainerDirective, { static: true }) public container!: BalloonContainerDirective;

  @Input() public waves: number = 2;
  @Input() public balloonsPerWave: number = 30;
  @Output() public readonly animationDone: EventEmitter<void> = new EventEmitter();

  public balloons: { level: number; color: string; left: number }[] = [];

  private waveCount: number = 0;

  public constructor() {
    // generate random balloons
  }

  public ngOnInit(): void {
    this.nextWave(100);
  }

  public loadBalloons(): void {
    const viewContainerRef = this.container.viewContainerRef;
    const color: any = ['orange', 'blue', 'green'][random(0, 2)];
    const level: any = ['level1', 'level2', 'level3', 'level4'][random(0, 3)];

    const componentRef = viewContainerRef.createComponent<BalloonComponent>(BalloonComponent);
    componentRef.instance.color = color;
    componentRef.instance.level = level;
    componentRef.instance.left = random(0, 95);
  }

  public containerClicked(): void {
    this.animationDone.emit();
  }

  private nextWave(interval: number): void {
    setTimeout(() => {
      this.waveCount++;
      for (let i = 0; i < this.balloonsPerWave; i++) {
        this.loadBalloons();
      }

      if (this.waveCount < this.waves) {
        this.nextWave(500);
      } else {
        // set animation done after 3s because that's the longest balloon animation
        setTimeout(() => {
          this.animationDone.emit();
        }, 3000);
      }
    }, interval);
  }
}
