<div class="input-container flex flex-col items-stretch justify-start" [class.hasIcon]="iconName" [formGroup]="parent">
  <label class="block text-gray-700 text-sm font-bold mb-2 w-full" *ngIf="label">{{ label }} <span *ngIf="requiredField" class="required">*</span></label>
  <input
    class="focus:outline-none focus:shadow-outline-none w-full"
    [type]="type"
    [pattern]="pattern"
    [placeholder]="placeholder"
    [formControlName]="control"
    (keypress)="keypressed.emit($event)"
    (blur)="onBlur($event)"
    [readonly]="readonly"
    data-hj-allow
    [ngClass]="{ hasError: hasError }"
    #input />

  <i *ngIf="iconName" [class]="iconName"></i>

  <div class="flex justify-between items-center gap-2" *ngIf="hasError || maxLength > 0">
    <div class="text-sm text-red-400">
      <span *ngIf="hasError">{{ getErrorMessage }}</span>
    </div>
    <div>
      <span class="text-xs text-rs-dark-grey" [ngClass]="{ 'text-rs-red': valueLength > maxLength }" *ngIf="maxLength > 0"
        >{{ valueLength }} / {{ maxLength }} characters</span
      >
    </div>
  </div>
</div>
