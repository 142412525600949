import { NgModule } from '@angular/core';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import {
  faBuildingColumns,
  faCheck,
  faChevronDown,
  faCircleCheck,
  faCircleNotch,
  faCloudArrowDown,
  faCode,
  faEllipsisV,
  faExclamationTriangle,
  faFile,
  faFlag,
  faGear,
  faInfoCircle,
  faMessage,
  faPencil,
  faTimes,
  faUser,
  faUserGroup
} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [],
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class IconsModule {
  public constructor(library: FaIconLibrary, config: FaConfig) {
    config.fixedWidth = true;

    library.addIcons(
      faBuildingColumns,
      faUserGroup,
      faUser,
      faClipboard,
      faCircleCheck,
      faCircleNotch,
      faCheck,
      faPencil,
      faMessage,
      faGear,
      faCloudArrowDown,
      faCode,
      faExclamationTriangle,
      faEllipsisV,
      faInfoCircle,
      faChevronDown,
      faFlag,
      faFile,
      faTimes
    );
  }
}
