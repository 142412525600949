export const environment = {
  env: 'dev',
  production: true,
  api: 'https://us-central1-rally-starter-147101.cloudfunctions.net',
  firebase: {
    projectId: 'rally-starter-147101',
    appId: '1:160526941605:web:7972d04c08a30009bbfc4c',
    databaseURL: 'https://rally-starter-147101-default-rtdb.firebaseio.com',
    storageBucket: 'rally-starter-147101.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyCDEiV731gH32pj3wPag6NCa414mmJ05Fw',
    authDomain: 'dev.rallystarter.com',
    messagingSenderId: '160526941605',
    measurementId: 'G-1CFN5CDV0D'
  },
  gtm: {
    id: ''
  },
  algolia: {
    appId: 'ZCTOT9OZX0',
    apiKey: '02bdac7555ec64616ad52ebf7efeb544',
    searchKey: '1268d24f915d60d8ae2666bf0b8d545c'
  },
  tinyMCE: {
    apiKey: 'r7t8aw83lf1ctj4isb6h7ht9pk9w3jf0zxgvlcgbzmwvhnoo'
  },
  mailchimp: {
    clientId: '399845025391'
  },
  siteURL: 'https://dev.rallystarter.com',
  embedSiteURL: 'https://embed.dev.rallystarter.com',
  appCheck: {
    recaptcha3SiteKey: '6Le4mSgeAAAAAHdcFmd9Sa75hB-AJcdTAZBYH9oV',
    isTokenAutoRefreshEnabled: true
  },
  emulators: {
    functions: false,
    firestore: false,
    authentication: false,
    pubsub: false,
    storage: false
  },
  feature: {
    chatgpt: true
  }
};
