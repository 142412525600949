<div class="help-sidebar" [@slideInOut]="show ? 'in' : 'out'">
  <div class="header">
    <span class="cursor-pointer text-lg flex items-center mt-1" (click)="onClose()">
      <i class="far fa-chevron-left text-xs mr-2"></i>
      Back
    </span>

    <span class="cursor-pointer text-2xl text-rs-black" (click)="onClose()">
      <i class="fal fa-times"></i>
    </span>
  </div>
  <div class="overflow-y-auto p-8 h-full"><ng-content></ng-content></div>
</div>

<div class="help-overlay" *ngIf="show" (click)="onClose()"></div>
