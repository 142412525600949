import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '@shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { debounceTime } from 'rxjs';
import { NgIf } from '@angular/common';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, InputComponent, NgIf]
})
export class ChangePasswordDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public formLoading: boolean = false;

  private fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  private authService: AuthService = inject(AuthService);
  private toasterService: ToastrService = inject(ToastrService);
  private dialogRef: DynamicDialogRef = inject(DynamicDialogRef);
  private dialogConfig: DynamicDialogConfig<{ email: string; oobCode: string }> = inject(DynamicDialogConfig);

  public constructor() {
    this.form = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });

    this.form.controls.confirmPassword.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (value && value !== this.form.controls.password.value) {
        this.form.controls.confirmPassword.setErrors({ custom: 'Passwords do not match' });
      } else {
        if (this.form.controls.confirmPassword.hasError('custom')) {
          this.form.controls.confirmPassword.setErrors(null);
        }
      }
    });
  }

  public ngOnInit(): void {}

  public get submitDisabled(): boolean {
    return this.form.invalid || this.formLoading || this.form.controls.password.value !== this.form.controls.confirmPassword.value;
  }

  public onSubmit(): void {
    if (this.form.valid && this.form.controls.password.value === this.form.controls.confirmPassword.value) {
      this.formLoading = true;
      this.authService.confirmPasswordReset(this.dialogConfig.data?.oobCode as string, this.form.value.password).then(
        () => {
          this.toasterService.success('Password successfully changed. Please login to continue.');
          this.dialogRef.close();
        },
        (error) => {
          this.toasterService.error(error.message);
        }
      );
    }
  }
}
