<div class="find-reps-dialog">
  <div class="find-reps-dialog-content flex flex-col gap-4">
    <div class="find-reps-dialog-body" [formGroup]="form">
      <div class="find-reps-dialog-body-content">
        <div class="block relative">
          <app-google-address [parent]="form" control="address" class="w-full" appendTo="body"></app-google-address>
        </div>
      </div>
    </div>
    <div class="find-reps-dialog-footer">
      <button pButton class="p-button-outlined flex flex-nowrap gap-2 justify-center items-center" (click)="onSubmit()" [disabled]="submitDisabled">
        <span>Find</span>
        <i class="fas fa-circle-notch fa-spin" *ngIf="formLoading"></i>
      </button>
    </div>
  </div>
</div>
