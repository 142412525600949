import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IconsModule } from '@shared/modules/icons.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ToastrModule } from 'ngx-toastr';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SkeletonModule } from 'primeng/skeleton';

import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { BalloonAnimationComponent } from './components/balloon-animation/balloon-animation.component';
import { BalloonComponent } from './components/balloon/balloon.component';
import { BlogCardComponent } from './components/blog-card/blog-card.component';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';
import { ComponentLoadingComponent } from './components/component-loading/component-loading.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DetachedIframeComponent } from './components/dettached-iframe/detached-iframe.component';
import { EmbedRallyModalComponent } from './components/embed-rally-modal/embed-rally-modal.component';
import { FindRepresentativesComponent } from './components/find-representatives/find-representatives.component';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { GoogleAddressComponent } from './components/google-address/google-address.component';
import { HeaderComponent } from './components/header/header.component';
import { HelpSidebarComponent } from './components/help-sidebar/help-sidebar.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { InputCheckboxComponent } from './components/input-checkbox/input-checkbox.component';
import { InputRadioComponent } from './components/input-radio/input-radio.component';
import { InputComponent } from './components/input/input.component';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RallyCardComponent } from './components/rally-card/rally-card.component';
import { ScrollIndicatorComponent } from './components/scroll-indicator/scroll-indicator.component';
import { SectionScrollerComponent } from './components/section-scroller/section-scroller.component';
import { SelectComponent } from './components/select/select.component';
import { ShareRallyActionsComponent } from './components/share-rally-actions/share-rally-actions.component';
import { TermsAndPolicyComponent } from './components/terms-and-policy/terms-and-policy.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { VjsPlayerComponent } from './components/vjs-player/vjs-player.component';
import { BalloonContainerDirective } from './directives/balloon-container.directive';
import { EllipsisDirective } from './directives/ellipsis.directive';
import { ScrollSectionDirective } from './directives/scroll-section.directive';
import { ManageRallyGuard } from './guards/manage-rally.guard';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule,
    NgxDatatableModule,
    NgxTippyModule,
    ImageCropperModule,
    IconsModule,
    GoogleAddressComponent,
    AvatarModule,
    AutoCompleteModule,
    MenuModule,
    ProgressBarModule,
    CheckboxModule,
    RadioButtonModule,
    ButtonModule,
    FormsModule,
    SkeletonModule,
    InputComponent,
    SelectComponent,
    EllipsisDirective,
    ConfirmationDialogComponent,
    HeaderComponent,
    LoginModalComponent,
    ForgotPasswordDialogComponent,
    ChangePasswordDialogComponent,
    InputRadioComponent,
    InputCheckboxComponent,
    FindRepresentativesComponent,
    TextareaComponent,
    ImageUploadComponent,
    ImageCropperComponent,
    AutoCompleteComponent,
    RallyCardComponent,
    BlogCardComponent,
    SectionScrollerComponent,
    ScrollSectionDirective,
    PageLoaderComponent,
    TermsAndPolicyComponent,
    ShareRallyActionsComponent,
    GenericModalComponent,
    BalloonComponent,
    BalloonAnimationComponent,
    BalloonContainerDirective,
    HelpSidebarComponent,
    EmbedRallyModalComponent,
    ComponentLoadingComponent,
    VjsPlayerComponent,
    ScrollIndicatorComponent,
    ProgressBarComponent,
    SafePipe,
    DetachedIframeComponent
  ],
  providers: [ManageRallyGuard]
})
export class SharedModule {}
