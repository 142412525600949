<div class="radio-input-container flex flex-col justify-start items-stretch" [formGroup]="parent">
  <label class="block text-gray-700 text-sm font-bold mb-2 w-full" *ngIf="label">{{ label }} <span *ngIf="requiredField" class="required">*</span></label>
  <div class="flex flex-wrap gap-4">
    <p-radioButton
      *ngFor="let option of options"
      [value]="option.value"
      [label]="option.label"
      [formControlName]="control"
      (onClick)="onChange($event)"></p-radioButton>
  </div>
</div>
