import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RallyService } from '@shared/services/rally.service';
import { ToastrService } from 'ngx-toastr';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageRallyGuard {
  public constructor(private rallyService: RallyService, private auth: Auth, private router: Router, private toasterService: ToastrService) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.rallyService.getRally(route.params.id).pipe(
      map((rally) => rally?.roles.owners.includes(this.auth.currentUser?.uid as string) || rally?.roles.admins?.includes(this.auth.currentUser?.uid as string)),
      map((isOwner) => {
        if (isOwner) {
          return true;
        }

        this.toasterService.error('You do not have permission to manage this rally.');
        this.router.navigate(['/']);
        return false;
      })
    );
  }
}
