/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
import '../environment/dev.js';
import { ensureTokenIsValid, secretToken } from './secrets.js';
import { getTrustedTypes, getTrustedTypesPolicy } from './trusted_types.js';
/**
 * Runtime implementation of `TrustedHTML` in browsers that don't support it.
 */
class HtmlImpl {
  constructor(html, token) {
    ensureTokenIsValid(token);
    this.privateDoNotAccessOrElseWrappedHtml = html;
  }
  toString() {
    return this.privateDoNotAccessOrElseWrappedHtml.toString();
  }
}
function createTrustedHtmlOrPolyfill(html, trusted) {
  return trusted !== null && trusted !== void 0 ? trusted : new HtmlImpl(html, secretToken);
}
const GlobalTrustedHTML = typeof window !== 'undefined' ? window.TrustedHTML : undefined;
/**
 * Also exports the constructor so that instanceof checks work.
 */
export const SafeHtml = GlobalTrustedHTML !== null && GlobalTrustedHTML !== void 0 ? GlobalTrustedHTML : HtmlImpl;
/**
 * Builds a new `SafeHtml` from the given string, without enforcing safety
 * guarantees. It may cause side effects by creating a Trusted Types policy.
 * This shouldn't be exposed to application developers, and must only be used as
 * a step towards safe builders or safe constants.
 */
export function createHtmlInternal(html) {
  var _a;
  /** @noinline */
  const noinlineHtml = html;
  return createTrustedHtmlOrPolyfill(noinlineHtml, (_a = getTrustedTypesPolicy()) === null || _a === void 0 ? void 0 : _a.createHTML(noinlineHtml));
}
/**
 * An empty `SafeHtml` constant.
 * Unlike the function above, using this will not create a policy.
 */
export const EMPTY_HTML = /* #__PURE__ */(() => {
  var _a;
  return createTrustedHtmlOrPolyfill('', (_a = getTrustedTypes()) === null || _a === void 0 ? void 0 : _a.emptyHTML);
})();
/**
 * Checks if the given value is a `SafeHtml` instance.
 */
export function isHtml(value) {
  var _a;
  return ((_a = getTrustedTypes()) === null || _a === void 0 ? void 0 : _a.isHTML(value)) || value instanceof HtmlImpl;
}
/**
 * Returns the value of the passed `SafeHtml` object while ensuring it
 * has the correct type.
 *
 * Returns a native `TrustedHTML` or a string if Trusted Types are disabled.
 */
export function unwrapHtml(value) {
  var _a;
  if ((_a = getTrustedTypes()) === null || _a === void 0 ? void 0 : _a.isHTML(value)) {
    return value;
  } else if (value instanceof HtmlImpl) {
    return value.privateDoNotAccessOrElseWrappedHtml;
  } else {
    let message = '';
    if (process.env.NODE_ENV !== 'production') {
      message = 'Unexpected type when unwrapping SafeHtml';
    }
    throw new Error(message);
  }
}