import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

/**
 * Levels define whether the images appear closer or farther
 * level1 is appears closer and moves faster while lever4 is 'farther' and flies slower
 */

const flyOut = trigger('flyOut', [
  state('level1', style({ top: '-200px' })),
  state('level2', style({ top: '-200px' })),
  state('level3', style({ top: '-200px' })),
  state('level4', style({ top: '-200px' })),
  transition('void => level1', [style({ top: '120%' }), animate('1.5s ease-in')]),
  transition('void => level2', [style({ top: '120%' }), animate('2s ease-in')]),
  transition('void => level3', [style({ top: '120%' }), animate('2.5s ease-in')]),
  transition('void => level4', [style({ top: '120%' }), animate('3s ease-in')])
]);

@Component({
  selector: 'app-balloon',
  templateUrl: './balloon.component.html',
  styleUrls: ['./balloon.component.scss'],
  animations: [flyOut],
  standalone: true
})
export class BalloonComponent implements OnInit {
  @Input() public level: 'level1' | 'level2' | 'level3' | 'level4' = 'level1';
  @Input() public color: 'blue' | 'green' | 'orange' = 'blue';
  @Input() public left: number = 0; // in percentage

  public constructor() {}

  public ngOnInit(): void {}
}
