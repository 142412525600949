import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-terms-and-policy',
  templateUrl: './terms-and-policy.component.html',
  styleUrls: ['./terms-and-policy.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CheckboxModule, NgIf]
})
export class TermsAndPolicyComponent implements OnInit {
  @Input() public control: string = 'terms';
  @Input() public parent!: UntypedFormGroup;

  public checkControl: AbstractControl;
  public color: string = 'primary';

  public constructor() {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }
    this.checkControl = this.parent.get(this.control) as AbstractControl;
  }

  public get hasError(): boolean {
    return this.checkControl.invalid && this.checkControl.dirty;
  }
}
