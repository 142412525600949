export const formatPhone = (value: string): string => {
  let newVal = value.replace(/\D/g, '');

  if (newVal.length === 0) {
    newVal = '';
  } else if (newVal.length <= 3) {
    newVal = newVal.replace(/^(\d{0,3})/, '$1');
  } else if (newVal.length <= 6) {
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
  } else if (newVal.length <= 10) {
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
  } else {
    newVal = newVal.substring(0, 10);
    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
  }

  return newVal;
};

export const embedOverride = (fontFamily: string, fontColor: string): void => {
  // adding a bit of a timeout to allow the page to load all the elements
  // @Asitha this is how I'm planning on implementing the font and color override
  setTimeout(() => {
    const fontsToOverride = document.querySelectorAll('.override-font');
    const colorsToOverride = document.querySelectorAll('.override-color');
    const checkBoxes = document.querySelectorAll('.rs-checkbox.mat-checkbox-checked .mat-checkbox-frame');

    if (fontFamily !== '') {
      fontsToOverride.forEach((elem: any) => {
        elem.style.fontFamily = fontFamily;
      });
    }

    if (fontColor !== '') {
      colorsToOverride.forEach((elem: any) => {
        if (elem.nodeName === 'BUTTON' || elem.nodeName === 'A') {
          elem.style.backgroundColor = fontColor;
        } else {
          elem.style.color = fontColor;
        }
      });

      checkBoxes.forEach((checkBox) => {
        (checkBox as HTMLElement).style.borderColor = fontColor;
      });
    }
  }, 300);
};

export const sendParentHeight = () => {
  const resizeObserver = new ResizeObserver(() => {
    const height = document.body.scrollHeight;
    // post iframe height to parent
    window.parent.postMessage({ height }, '*');
  });

  // start observing a DOM node
  resizeObserver.observe(document.body);
};

export const slugify = (text: string): string => {
  return text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
};
