import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hyphen',
  standalone: true
})
export class HyphenPipe implements PipeTransform {
  public transform(value: string): string {
    return value.toLowerCase().replace(/\s+/g, '-');
  }
}
