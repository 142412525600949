module.exports = removeEventListener;
function removeEventListener(type, listener) {
  var elem = this;
  if (!elem.listeners) {
    return;
  }
  if (!elem.listeners[type]) {
    return;
  }
  var list = elem.listeners[type];
  var index = list.indexOf(listener);
  if (index !== -1) {
    list.splice(index, 1);
  }
}