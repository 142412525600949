<div class="image-cropper-container">
  <i class="fas fa-circle-notch fa-spin text-4xl" *ngIf="cropperLoading"></i>
  <image-cropper
    [imageChangedEvent]="event"
    [maintainAspectRatio]="true"
    [aspectRatio]="aspectRatio"
    format="png"
    [containWithinAspectRatio]="true"
    (imageCropped)="imageCropped($event)"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"></image-cropper>
  <div class="image-cropper-actions" *ngIf="!cropperLoading">
    <button class="rs-button dark" (click)="onCrop()">Crop</button>
  </div>
</div>
