<div class="image-upload-container" [formGroup]="parent">
  <input type="hidden" [formControlName]="control" />
  <input type="file" class="hidden" id="file" #file (change)="onFileChange($event)" [accept]="acceptedFileTypes" />
  <label for="file" class="upload-label" *ngIf="!previewURL">
    <i class="fal fa-upload"></i>
    <span class="text-base">{{ label }}</span>
  </label>
  <div class="text-sm text-red-400 mt-3" *ngIf="hasError">
    {{ getErrorMessage }}
  </div>
  <div class="image-upload-preview" *ngIf="previewURL">
    <img class="max-w-100 w-full border border-solid border-[#767676]" [src]="previewURL" />
    <label for="file" class="text-sm text-rs-darker-grey mt-3 cursor-pointer">{{ uploadLinkLabel }}</label>
  </div>
</div>
