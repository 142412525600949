import { Component, Input, OnInit } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  standalone: true,
  imports: [NgIf, FaIconComponent]
})
export class PageLoaderComponent implements OnInit {
  @Input() public show: boolean = false;
  @Input() public useLoadingCircle: boolean = false;

  public constructor() {}

  public ngOnInit(): void {}
}
