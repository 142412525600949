<div class="blog-card">
  <a class="hover-underline-parent" target="_blank" [title]="data.title" [href]="data.link">
    <div class="blog-background" [class]="backgroundSize" style="background-image: url('{{ data.image }}')">
      <img [src]="data.image" alt="" *ngIf="backgroundSize === 'contain'" class="w-full block sm:hidden" />
    </div>
    <div class="text-block">
      <p class="subtitle">{{ data.subtitle }}</p>
      <h4>{{ data.title }}</h4>
      <p class="bottom-text">{{ data.description }}</p>
    </div>
  </a>
</div>
