import { Clipboard } from '@angular/cdk/clipboard';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CheckboxOption, SelectOptionGroup } from '@shared/interface/common';
import { Rally } from '@shared/interface/rally';
import { customFonts } from '@shared/utils/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { debounceTime, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgIf, AsyncPipe } from '@angular/common';
import { numberValidator } from '@shared/utils/validators';
import { InputCheckboxComponent } from '../input-checkbox/input-checkbox.component';
import { InputComponent } from '../input/input.component';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'app-embed-rally-modal',
  templateUrl: './embed-rally-modal.component.html',
  styleUrls: ['./embed-rally-modal.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, SelectComponent, InputComponent, InputCheckboxComponent, NgIf, AsyncPipe]
})
export class EmbedRallyModalComponent implements OnInit {
  public form: FormGroup;
  public iframeSrc: Observable<SafeResourceUrl>;
  public customFontOptions: SelectOptionGroup[] = customFonts;
  public embedCode: string = '';
  public rally: Rally;
  public hideOptions: CheckboxOption[] = [
    {
      value: 'hideSummary',
      label: 'Hide Summary',
      checked: false
    },
    {
      value: 'hidePictures',
      label: 'Hide Pictures',
      checked: false
    }
  ];
  public includeOptions: CheckboxOption[] = [
    {
      value: 'targetAndActions',
      label: 'Include All Target and Actions',
      checked: false
    },
    {
      value: 'takeMoreActions',
      label: 'Include Take More Actions Button',
      checked: false
    }
  ];
  public sizingOptions: CheckboxOption[] = [
    {
      value: 'responsive',
      label: 'Automatically adjust to container (recommended)',
      checked: true
    },
    {
      value: 'manual',
      label: 'Set Manually',
      checked: false
    }
  ];

  private fb: FormBuilder = inject(FormBuilder);
  private sanitizer: DomSanitizer = inject(DomSanitizer);
  private clipboard: Clipboard = inject(Clipboard);
  private dialogConfig: DynamicDialogConfig<{ rally: Rally }> = inject(DynamicDialogConfig);

  public constructor() {
    this.rally = this.dialogConfig.data?.rally as Rally;

    this.form = this.fb.group({
      font: ['Poppins'],
      color: [''],
      hideOptions: [[]],
      includeOptions: [[]],
      sizingOptions: [[]],
      width: ['', [Validators.min(1), numberValidator]],
      height: ['', [Validators.min(1), numberValidator]]
    });

    this.form.valueChanges.pipe(debounceTime(500)).subscribe((val) => {
      if (val.sizingOptions.includes('responsive')) {
        this.sizingOptions[0].checked = true;
        this.sizingOptions[1].checked = false;
      } else {
        this.sizingOptions[0].checked = false;
        this.sizingOptions[1].checked = true;
      }
      this.setEmbedCode();
      this.setIframeSrc();
    });
  }

  public ngOnInit(): void {
    this.setEmbedCode();
    this.setIframeSrc();

    if (this.rally.actions.length < 2) {
      this.includeOptions.splice(1, 1);
    }
  }

  public setIframeSrc(): void {
    const rally = this.rally;
    const summary = !this.form.get('hideOptions')?.value.includes('hideSummary');
    const images = !this.form.get('hideOptions')?.value.includes('hidePictures');
    const allTargets = this.form.get('includeOptions')?.value.includes('targetAndActions');
    const takeMoreActions = this.form.get('includeOptions')?.value.includes('takeMoreActions');
    const manualWidth = this.form.get('sizingOptions')?.value.includes('manual') ? true : false;
    const font = this.form.get('font')?.value;
    const params = new URLSearchParams();
    params.append('isPreview', 'true');
    params.append('rallyEmbed', 'true');
    params.append('summary', summary.toString());
    params.append('images', images.toString());
    params.append('allTargets', allTargets.toString());
    params.append('takeMoreActions', takeMoreActions.toString());
    params.append('font', font.toString());
    params.append('fontColor', this.form.get('color')?.value.toString());
    params.append('manualWidth', manualWidth.toString());
    params.append('width', this.form.get('width')?.value.toString());
    params.append('height', this.form.get('height')?.value.toString());

    const url = `/rally/${rally.id}/${rally.slug}?${params.toString()}`;

    this.iframeSrc = of(this.sanitizer.bypassSecurityTrustResourceUrl(url));
  }

  public setEmbedCode(): void {
    const rally = this.rally;
    const summary = !this.form.get('hideOptions')?.value.includes('hideSummary');
    const images = !this.form.get('hideOptions')?.value.includes('hidePictures');
    const allTargets = this.form.get('includeOptions')?.value.includes('targetAndActions');
    const takeMoreActions = this.form.get('includeOptions')?.value.includes('takeMoreActions');
    const font = this.form.get('font')?.value;
    const fontColor = this.form.get('color')?.value;
    const manualWidth = this.form.get('sizingOptions')?.value.includes('manual');
    const width = this.form.get('width')?.value;
    const height = this.form.get('height')?.value;

    const code = `<rs-rally id="${rally.documentId}" rally="${rally.documentId}" summary="${summary}" images="${images}" manualWidth="${manualWidth}"
    width="${width}" height="${height}"
    allTargets="${allTargets}" takeMoreActions="${takeMoreActions}" font="${font}" fontColor="${fontColor}"></rs-rally>
    <script>
    const rallyElem = document.getElementById("${rally.documentId}");
    rallyElem.setAttribute("embedurl", encodeURI(window.location.href));
    const el = document.createElement('script');
    el.src = '${environment.embedSiteURL}/cdn/rallystarter.js';
    document.getElementsByTagName("head")[0].appendChild(el);
    </script>`;

    this.embedCode = code;
  }

  public onCopy(): void {
    this.clipboard.copy(this.embedCode);
  }
}
