import { Component, inject, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: true
})
export class ConfirmationDialogComponent implements OnInit {
  public data: { message: string; confirmText: string; cancelText: string };
  private dialogConfig: DynamicDialogConfig<{ message: string; confirmText: string; cancelText: string }> = inject(DynamicDialogConfig);
  private dialogRef: DynamicDialogRef = inject(DynamicDialogRef);

  public constructor() {
    this.data = this.dialogConfig.data as { message: string; confirmText: string; cancelText: string };
  }

  public ngOnInit(): void {
    if (!this.dialogConfig.data?.cancelText) {
      this.data.cancelText = 'Cancel';
    }

    if (!this.dialogConfig.data?.confirmText) {
      this.data.confirmText = 'Confirm';
    }
  }

  public onConfirm(): void {
    this.dialogRef.close(true);
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }
}
