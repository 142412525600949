<!-- Banner -->
<div class="p-2 text-center flex justify-center bg-rs-teal text-rs-dark-teal" *ngIf="currentPath !== '/rally/create'">
  <a routerLink="/rally/create" class="underline">Get Pro features FREE with code LETSRALLYWEB. Start your rally today!</a>
</div>
<!-- End Banner -->
<ng-container>
  <nav id="navbar" class="navbar-custom w-full sm:py-4 py-2" [ngClass]="[theme, position]" [class.borderBottom]="border">
    <div class="container mx-auto flex flex-wrap items-center justify-between">
      <a class="flex items-center cursor-pointer relative w-16 sm:w-[auto] truncate z-[99]" routerLink="/">
        <img [src]="logoURL" alt="Rally Starter" class="logo" />
      </a>
      <div class="flex items-center">
        <button
          (click)="toggleNavbar()"
          data-collapse-toggle="mobile-menu"
          type="button"
          class="m-menu bg-transparent inline-flex items-center p-2 ml-1 border-none md:hidden focus:outline-none"
          aria-controls="mobile-menu"
          aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <span class="hamburger" [class.open]="menuShow"></span>
        </button>
      </div>
      <div [ngClass]="{ hidden: !menuShow, burgermenu: menuShow }" class="justify-between items-center w-full md:flex md:w-auto md:order-1" id="mobile-menu">
        <ul class="flex flex-col mt-16 md:flex-row md:space-x-8 md:mt-0 list-none">
          <li *ngFor="let item of menuItems" class="nav-item" [class]="item.parentClass">
            <ng-container *ngIf="!item.children">
              <a *ngIf="item.clickFn" class="{{ item.class }}" (click)="item.clickFn()">{{ item.name }}</a>
              <a *ngIf="!item.clickFn" class="{{ item.class }}" routerLink="{{ item.path }}" (click)="menuShow = false" [queryParams]="item.queryParams">{{
                item.name
              }}</a>
            </ng-container>
            <ng-container *ngIf="item.children">
              <a class="{{ item.class }}" (click)="childMenu.toggle($event)">{{ item.name }}</a>
              <p-menu #childMenu [model]="item.children" class="header-menu" styleClass="header-menu" appendTo="body" [popup]="true"> </p-menu>
            </ng-container>
          </li>

          <div class="invisible md:visible" *ngIf="user | async as user; else login">
            <p-avatar
              *ngIf="user.customPhoto || user.photo"
              [image]="user.customPhoto || user.photo"
              shape="circle"
              [style]="{ 'background-color': 'rgb(75, 239, 205)' }"
              size="large"
              styleClass="cursor-pointer bg-rs-teal text-base border border-solid border-white text-white"
              (click)="accountMenu.toggle($event)"></p-avatar>
            <p-avatar
              *ngIf="!user.customPhoto && !user.photo"
              [label]="user.name?.slice(0, 1) || user.email.slice(0, 1)"
              shape="circle"
              [style]="{ 'background-color': 'rgb(75, 239, 205)' }"
              size="large"
              styleClass="cursor-pointer bg-rs-teal text-base border border-solid border-white text-white"
              (click)="accountMenu.toggle($event)"></p-avatar>
            <p-menu #accountMenu [model]="accountMenuItems" class="header-menu" styleClass="header-menu" appendTo="body" [popup]="true"> </p-menu>
          </div>

          <ng-template #login>
            <li class="nav-item flex">
              <a class="hover-underline-animation no-underline cursor-pointer relative" (click)="onLogin()">Log In</a>
            </li>
          </ng-template>
        </ul>
      </div>
    </div>
  </nav>
</ng-container>
