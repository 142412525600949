<div class="section-scroller">
  <div #sideNav class="sideNav sm:relative md:absolute top-0">
    <a
      [routerLink]="[]"
      [fragment]="item.sectionLabel | hyphen"
      class="sub"
      [class.active]="activeIdx === i"
      *ngFor="let item of sections; let i = index"
      (click)="onClick(i)">
      {{ item.sectionLabel }}</a
    >
  </div>
  <div #contentWrap class="contents">
    <ng-content></ng-content>
  </div>
</div>
