<div class="change-password-dialog">
  <div class="change-password-dialog-content">
    <div class="change-password-dialog-header">
      <h3 class="m-0">Change Password</h3>
    </div>
    <div class="change-password-dialog-body" [formGroup]="form">
      <app-input [parent]="form" label="New Password" control="password" type="password"></app-input>
      <app-input [parent]="form" label="Confirm Password" control="confirmPassword" type="password"></app-input>
    </div>
    <div class="change-password-dialog-footer">
      <button class="outline-none flex flex-row flex-nowrap justify-center items-center gap-[10px]" (click)="onSubmit()"
        [disabled]="submitDisabled">
        <span>Save</span>
        <i class="fas fa-circle-notch fa-spin" *ngIf="formLoading"></i>
      </button>
    </div>
  </div>
</div>
