import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgIf, NgClass } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgClass]
})
export class TextareaComponent implements OnInit, AfterViewInit {
  @ViewChild('textarea') public input!: ElementRef<HTMLTextAreaElement>;

  @Input() public parent: UntypedFormGroup;
  @Input() public control: string;
  @Input() public placeholder: string = '';
  @Input() public label: string = '';
  @Input() public readonly: boolean = false;
  @Input() public rows: number = 3;
  @Input() public maxLength: number = 0;
  @Input() public filterTextCount: string = '';
  @Input() public autoResize: boolean = false;

  @Output() public readonly keypressed: EventEmitter<any> = new EventEmitter<any>();

  public inputControl: AbstractControl;
  public valueLength: number = 0;

  public constructor() {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }
    this.inputControl = this.parent.get(this.control) as AbstractControl;
    this.valueLength = this.filterTextCount ? this.inputControl.value.replace(this.filterTextCount, '').length : this.inputControl.value.length;
  }

  public ngAfterViewInit(): void {
    this.adjustTextareaHeight();

    this.inputControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.adjustTextareaHeight();
      this.valueLength = this.filterTextCount ? this.inputControl.value.replace(this.filterTextCount, '').length : this.inputControl.value.length;
    });
  }

  public get hasError(): boolean {
    return this.inputControl && this.inputControl.invalid && this.inputControl.dirty;
  }

  public get requiredField(): boolean {
    return this.inputControl && this.inputControl.hasValidator(Validators.required);
  }

  public get getErrorMessage(): string {
    if (this.inputControl && this.hasError && this.inputControl.hasError('required')) {
      return 'This field is required.';
    } else if (this.inputControl.hasError(this.control)) {
      return this.inputControl.getError(this.control);
    } else if (this.inputControl.hasError('minlength')) {
      return `At least ${this.inputControl.getError('minlength').requiredLength} characters required`;
    } else if (this.inputControl.hasError('maxlength')) {
      return 'Max character length exceeded';
    }
    return 'Please enter a valid input';
  }

  public clearValue(): void {
    this.inputControl.setValue('');
  }

  public adjustTextareaHeight(): void {
    if (this.autoResize) {
      const textArea = this.input.nativeElement;
      textArea.style.overflow = 'hidden';
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight + 'px';
    }
  }
}
