<div class="relative w-full" [formGroup]="form">
  <p-autoComplete
    data-hj-allow
    class="w-full"
    [styleClass]="'w-full' + (hasError ? ' has-error' : '')"
    formControlName="address"
    [placeholder]="placeholder"
    emptyMessage="Address not found. Please enter a valid US address."
    (onSelect)="onSelected($event)"
    optionLabel="description"
    (completeMethod)="onAddressInputChange($event)"
    [suggestions]="options"
    [forceSelection]="forceSelection"
    [appendTo]="appendTo"></p-autoComplete>
  @if (hasError) {
    <div class="text-sm text-red-400">
      <span>This field is required.</span>
    </div>
  }
</div>
