import { inject, Injectable, signal } from '@angular/core';
import { Analytics, setUserId, setUserProperties } from '@angular/fire/analytics';
import {
  Auth,
  authState,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  IdTokenResult,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
  User,
  UserCredential,
  verifyPasswordResetCode
} from '@angular/fire/auth';
import { doc, docData, DocumentReference, Firestore } from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Representative, UnauthenticatedUser, User as IUser } from '@shared/interface/user';
import { GovTypes } from '@shared/utils/constants';
import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

// TODO: This needs refactoring, there's a lot component currently using this
const emptyUser: UnauthenticatedUser = {
  firstName: '',
  lastName: '',
  email: '',
  actionsTaken: {},
  subscriptions: {},
  petitions: {}
};

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUser: any = null;
  public currentUserUID: string | null = null;
  public authStatusSub = new BehaviorSubject(this.currentUser);
  public currentAuthStatus = this.authStatusSub.asObservable() as Observable<UserCredential>;
  public isAdmin: Observable<boolean>;
  public unauthenticatedUser = new BehaviorSubject<UnauthenticatedUser>(emptyUser);
  public unauthenticatedUserCache: UnauthenticatedUser = emptyUser;
  public userHasRepresentatives = signal<boolean>(false); // todo: revisit after refactor

  private analytics: Analytics = inject(Analytics);

  public constructor(
    private auth: Auth,
    private firestore: Firestore,
    private cookieService: CookieService,
    private fireFn: Functions
  ) {
    this.authStatusListener();
  }

  public loginWithGoogle(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();

    return signInWithPopup(this.auth, provider);
  }

  public loginWithTwitter(): Promise<UserCredential> {
    const provider = new TwitterAuthProvider();

    return signInWithPopup(this.auth, provider);
  }

  public loginWithFacebook(): Promise<UserCredential> {
    const provider = new FacebookAuthProvider();

    return signInWithPopup(this.auth, provider);
  }

  public login(username: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, username, password);
  }

  public register(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  public resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email, {
      url: window.location.origin
    });
  }

  public verifyPasswordResetCode(code: string): Promise<string> {
    return verifyPasswordResetCode(this.auth, code);
  }

  public confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    return confirmPasswordReset(this.auth, code, newPassword);
  }

  public logout(): Promise<void> {
    this.clearAlgoliaKey();
    this.algoliaKey();
    return signOut(this.auth);
  }

  public authStatusListener(): void {
    onAuthStateChanged(this.auth, (credential) => {
      if (credential) {
        this.currentUser = credential;
        this.currentUserUID = (credential as User).uid;

        this.getUserDataFromFB();
      } else {
        // If the user is not logged in, then we'll try to check the session storage data if they have them
        this.getLocalUserData();
        this.currentUser = null;
        this.currentUserUID = null;
        this.authStatusSub.next(null);
        if (this.cookieService.get('algoliaKeyFilters').includes('Admins')) {
          this.clearAlgoliaKey();
          this.algoliaKey();
        }
      }
    });
  }

  public getUserDataFromFB(): void {
    if (this.currentUserUID) {
      const docRef = doc(this.firestore, 'users', this.currentUserUID) as DocumentReference<IUser>;
      const user = docData<IUser>(docRef, { idField: 'id' }).pipe(untilDestroyed(this));
      setUserId(this.analytics, this.currentUserUID);
      user.subscribe((u) => {
        if (u) {
          this.currentUser = { ...this.currentUser, ...u };
          if (environment.env === 'prod') {
            try {
              // Set the user ID for HotJar
              (window as any).hj('identify', this.currentUserUID, {
                Email: this.currentUser.email,
                Name: this.currentUser.name,
                ID: this.currentUserUID
              });
            } catch (e) {
              console.error(e);
            }
          }
          this.userHasRepresentatives.set(this.representatives([GovTypes.allCongress]).length > 0);

          if (this.currentUser.isSuperAdmin && !this.cookieService.get('algoliaKeyFilters').includes('Admins')) {
            this.clearAlgoliaKey();
            this.algoliaKey();
          }

          setUserProperties(this.analytics, {
            email: u.email,
            type: u.type
          });

          this.authStatusSub.next(this.currentUser);
        }
      });
    }
  }

  public getAuthStatus(): Observable<UserCredential> {
    return this.currentAuthStatus;
  }

  public hasAdminAccess(): void {
    this.isAdmin = authState(this.auth).pipe(
      switchMap((user: User | null) => {
        if (!user) {
          return of(false);
        }

        // eslint-disable-next-line
        return user.getIdTokenResult().then((results: IdTokenResult) => {
          return results.claims.admin as boolean;
        });
      }),
      tap((isAdmin: boolean) => {
        if (isAdmin) {
          httpsCallableData<undefined, { key: string; filters: string }>(this.fireFn, 'getAlgoliaKey')()
            .pipe(
              tap((res: { key: string; filters: string }) => {
                this.cookieService.set('algoliaKey', res.key, DateTime.now().plus({ hours: 12 }).toJSDate(), '/', undefined, true, 'Lax');
                this.cookieService.set('algoliaKeyFilters', res.filters, DateTime.now().plus({ hours: 12 }).toJSDate(), '/', undefined, true, 'Lax');

                // Set local storage for the key
                sessionStorage.setItem('algoliaKey', res.key);
                sessionStorage.setItem('algoliaKeyFilters', res.filters);
              }),
              take(1)
            )
            .subscribe();
        }
      })
    );
  }

  public algoliaKey(): Observable<string> {
    if (this.cookieService.check('algoliaKey')) {
      return of(this.cookieService.get('algoliaKey'));
    }

    if (sessionStorage.getItem('algoliaKey')) {
      return of(sessionStorage.getItem('algoliaKey') as string);
    }

    return this.getAlgoliaKey();
  }

  public fetchUserDataFromFB(): void {
    if (this.currentUserUID) {
      const docRef = doc(this.firestore, 'users', this.currentUserUID);
      const user = docData(docRef, { idField: 'id' }).pipe(take(1));

      user.subscribe((u) => {
        if (u) {
          this.currentUser = { ...this.currentUser, ...u };
          this.userHasRepresentatives.set(this.representatives([GovTypes.allCongress]).length > 0);
        }
      });
    }
  }

  public representatives(actionTargetTypes: string[]): Representative[] {
    const user = this.currentUserUID ? this.currentUser : this.unauthenticatedUserCache;
    const representatives: Representative[] | undefined = user.representatives;
    // change the type to all congress if the actionTargetTypes passed is both onlySenators and onlyRepresentatives
    let actionTargetType = actionTargetTypes.length > 0 ? actionTargetTypes[0] : GovTypes.allCongress;
    if (actionTargetTypes.length > 1 && actionTargetTypes.every((at) => at === GovTypes.onlySenators || at === GovTypes.onlyRepresentatives)) {
      actionTargetType = GovTypes.allCongress;
    }

    let comparer = '';
    if (actionTargetType === GovTypes.onlySenators) {
      comparer = 'Sen';
    } else if (actionTargetType === GovTypes.onlyRepresentatives) {
      comparer = 'Rep';
    }

    return representatives ? representatives.filter((r: Representative) => (actionTargetType === GovTypes.allCongress ? true : r.title === comparer)) : [];
  }

  private getAlgoliaKey(): Observable<string> {
    // Get query params to check if the site is embedded
    const urlParams = new URLSearchParams(window.location.search);
    const isEmbedded = urlParams.get('rallyEmbed') === 'true';

    if (isEmbedded) {
      return of('');
    }

    const key = environment.algolia.searchKey;
    this.cookieService.set('algoliaKey', key, DateTime.now().plus({ hours: 12 }).toJSDate(), '/', undefined, true, 'Lax');
    this.cookieService.set('algoliaKeyFilters', '', DateTime.now().plus({ hours: 12 }).toJSDate(), '/', undefined, true, 'Lax');

    // Set local storage for the key
    sessionStorage.setItem('algoliaKey', key);
    sessionStorage.setItem('algoliaKeyFilters', '');

    return of(key);
  }

  private getLocalUserData(): void {
    try {
      const userStr = sessionStorage.getItem('unauthenticatedUser');
      if (userStr) {
        this.unauthenticatedUserCache = JSON.parse(userStr);
        this.unauthenticatedUser.next(this.unauthenticatedUserCache);

        this.userHasRepresentatives.set(this.representatives([GovTypes.allCongress]).length > 0);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private clearAlgoliaKey(): void {
    this.cookieService.delete('algoliaKey');
    this.cookieService.delete('algoliaKeyFilters');
    sessionStorage.removeItem('algoliaKey');
    sessionStorage.removeItem('algoliaKeyFilters');
  }
}
