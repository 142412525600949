import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appScrollSection]',
  standalone: true
})
export class ScrollSectionDirective implements OnInit {
  @Input() public sectionLabel: string = '';
  public el: ElementRef<HTMLElement>;

  private elementRef = inject(ElementRef);

  public constructor() {
    this.el = this.elementRef;
  }

  public ngOnInit(): void {
    // Assign the sectionLabel as the id to jump to the section by id
    if (this.sectionLabel) {
      this.el.nativeElement.id = this.sectionLabel.toLowerCase().replace(/\s+/g, '-');
    }
  }
}
