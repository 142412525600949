/* eslint-disable @angular-eslint/no-forward-ref */
import { Component, ContentChild, DestroyRef, EventEmitter, inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RallyTarget } from '@shared/interface/rally';
import { AuthService } from '@shared/services/auth.service';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteSelectEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { forkJoin, map, Observable, of, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SkeletonModule } from 'primeng/skeleton';
import { PrimeTemplate } from 'primeng/api';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, AutoCompleteModule, PrimeTemplate, NgTemplateOutlet, SkeletonModule, AsyncPipe]
})
export class AutoCompleteComponent implements OnInit {
  @ContentChild(TemplateRef) public templateRef: TemplateRef<any>;
  @ViewChild(AutoComplete) public autoComplete: AutoComplete;

  @Input() public itemTemplate: TemplateRef<any>;
  @Input() public placeholder: string = '';
  @Input() public label: string = '';
  @Input() public otherValue: boolean = false;
  @Input() public otherValueLabel: string = 'No results found, click here to add your own value';
  @Input() public selectOtherValue: boolean = false;
  @Input() public tabIndex: number = 1;
  @Input() public indexes: string[] = [];
  @Input() public valueLabel: string = '';
  @Input() public useDefaultKey: boolean = false;
  @Input() public appendTo: string | undefined = undefined;
  @Input() public form: FormGroup;
  @Input() public completeOnFocus: boolean = false;

  @Output() public readonly suggestionClicked = new EventEmitter<{ query: any }>();
  @Output() public readonly otherValueClicked: EventEmitter<string> = new EventEmitter<string>();

  public suggestions: any[] = [];
  public searchClient: SearchClient;
  public algoliaKey$: Observable<string>;
  public key: string = '';

  private fb: FormBuilder = inject(FormBuilder);
  private authService: AuthService = inject(AuthService);
  private destroyRef = inject(DestroyRef);

  public constructor() {}

  public ngOnInit() {
    if (!this.form) {
      this.form = this.fb.group({
        search: ['']
      });

      if (this.selectOtherValue) {
        this.form.controls.search.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
          this.suggestionClicked.emit({
            query: {
              objectID: value,
              name: value
            }
          });
        });
      }
    }

    if (this.useDefaultKey) {
      this.algoliaKey$ = of(environment.algolia.apiKey);
      this.key = environment.algolia.apiKey;
      this.searchClient = algoliasearch(environment.algolia.appId, this.key);
    } else {
      this.algoliaKey$ = this.authService.algoliaKey().pipe(
        take(1),
        tap((key) => {
          this.key = key;
          this.searchClient = algoliasearch(environment.algolia.appId, this.key);
        })
      );
    }
  }

  public onSelect(event: AutoCompleteSelectEvent) {
    this.suggestionClicked.emit({ query: event.value });
  }

  public onOtherValueClicked(): void {
    this.otherValueClicked.emit(this.form.value.search);
  }

  public onCurrentValueClicked(value: string) {
    this.suggestionClicked.emit({
      query: {
        objectID: value,
        name: value
      }
    });

    this.autoComplete.hide();
  }

  public onSearch(event: AutoCompleteCompleteEvent): void {
    const queryIndexes = this.indexes.map((index) => this.searchClient.initIndex(index));

    forkJoin([...queryIndexes.map((index) => index.search<RallyTarget>(event.query))])
      .pipe(
        take(1),
        map((res) => res.map((r) => r.hits).flat())
      )
      .subscribe((suggestions) => {
        this.suggestions = suggestions;
      });
  }
}
