<span class="flex items-center share-wrap" [class]="size">
  <a [href]="getShareURL('facebook')" (click)="logEvents('facebook')" target="_blank">
    <i class="fa-brands fa-facebook-f"></i>
  </a>
  <a [href]="getShareURL('twitter')" (click)="logEvents('twitter')" target="_blank">
    <span class="twitter-icon">
      <span class="x-twitter w-4 h-4"></span>
    </span>
  </a>
  <a [href]="getShareURL('linkedin')" (click)="logEvents('linkedin')" target="_blank">
    <i class="fa-brands fa-linkedin-in"></i>
  </a>
  <a [href]="getShareURL('email')" (click)="logEvents('email')" target="_blank">
    <i class="fas fa-envelope"></i>
  </a>
</span>
