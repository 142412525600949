import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { formatPhone } from '@shared/utils/helpers';
import { debounceTime } from 'rxjs';
import { NgIf, NgClass } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgClass]
})
export class InputComponent implements OnInit {
  @ViewChild('input') public input: ElementRef<HTMLInputElement>;

  @Input() public parent!: UntypedFormGroup;
  @Input() public control!: string;
  @Input() public placeholder: string = '';
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public type: string = 'input';
  @Input() public showClear: boolean = false;
  @Input() public pattern: string = '';
  @Input() public mask: string = '';
  @Input() public phoneMask: boolean = false; // Temporary only until we can sort out the issue with the mask plugin
  @Input() public maxLength: number = 0;
  @Input() public iconName: string = '';

  @Output() public readonly keypressed: EventEmitter<any> = new EventEmitter<any>();

  public inputControl: AbstractControl;
  public valueLength: number = 0;
  public hasError: boolean = false;

  public constructor() {}

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }

    this.inputControl = this.parent.get(this.control) as AbstractControl;

    if (this.inputControl) {
      this.valueLength = this.inputControl.value.length || 0;
      this.inputControl.valueChanges.pipe(untilDestroyed(this), debounceTime(500)).subscribe(() => {
        this.hasError = this.inputControl.invalid && this.inputControl.dirty;
        this.valueLength = this.inputControl.value.length;
      });
    }
  }

  public get requiredField(): boolean {
    return this.inputControl.hasValidator(Validators.required);
  }

  public get getErrorMessage(): string {
    if (this.hasError || this.inputControl.invalid) {
      if (this.inputControl.hasError('required')) {
        return 'This field is required.';
      } else if (this.inputControl.hasError('minlength')) {
        return `At least ${this.inputControl.getError('minlength').requiredLength} characters required`;
      } else if (this.inputControl.hasError('maxlength')) {
        return 'Max character length exceeded';
      } else if (this.inputControl.hasError('file')) {
        return 'Please upload a valid file';
      } else if (this.inputControl.hasError('url') || this.inputControl.hasError('website')) {
        return 'Please enter a valid website including http/https protocol';
      } else if (this.inputControl.hasError('email')) {
        return 'Please enter a valid email';
      } else if (this.inputControl.hasError('exists')) {
        return 'Value already exists';
      } else if (this.inputControl.hasError('numeric')) {
        return 'Value must be numeric';
      } else if (this.inputControl.hasError('fullName')) {
        return 'Please enter a valid first and last name';
      } else if (this.inputControl.hasError('custom')) {
        return this.inputControl.getError('custom');
      }
    }

    return 'Please enter a valid input';
  }

  public clearValue(): void {
    this.inputControl.setValue('');
  }

  public onBlur(e: any): void {
    this.hasError = this.inputControl.invalid && this.inputControl.touched;

    if (this.phoneMask) {
      // strip any non-digits
      const newVal = formatPhone(e.target.value);

      this.parent.get(this.control)?.patchValue(newVal);
      this.inputControl.updateValueAndValidity();
    }
  }
}
