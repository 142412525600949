module.exports = addEventListener;
function addEventListener(type, listener) {
  var elem = this;
  if (!elem.listeners) {
    elem.listeners = {};
  }
  if (!elem.listeners[type]) {
    elem.listeners[type] = [];
  }
  if (elem.listeners[type].indexOf(listener) === -1) {
    elem.listeners[type].push(listener);
  }
}