import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-detached-iframe',
  templateUrl: './detached-iframe.component.html',
  styleUrls: ['./detached-iframe.component.scss'],
  standalone: true
})
export class DetachedIframeComponent implements AfterViewInit {
  @Input() public src: SafeResourceUrl;

  public constructor(private ref: ChangeDetectorRef) {}

  public ngAfterViewInit() {
    this.ref.detach();
  }
}
